import {HttpClient, HttpContext} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AppConfig} from '../app.config';
import {Door, DoorListItem, DoorOpen} from '../domain/models/door/door';
import {HTTP_SUPPRESS_LOGIN_DIALOG} from '../custom-http-interceptor';

@Injectable({
  providedIn: 'root',
})
export class DoorProvider {
  constructor(private http: HttpClient) {
  }

  getDoor(id: string): Observable<Door> {
    return this.http.get<Door>(
      `${AppConfig.getApiUrl()}/doors/${id}`, {
        headers: {'auth-key': AppConfig.authKey},
        context: new HttpContext().set(HTTP_SUPPRESS_LOGIN_DIALOG, true),
      });
  }

  getAllDoors(storeHandle: string): Observable<DoorListItem[]> {
    return this.http.get<DoorListItem[]>(
      `${AppConfig.getApiUrl()}/stores/${storeHandle}/doors`, {
        headers: {'auth-key': AppConfig.authKey},
      });
  }

  openDoor(id: string): Observable<DoorOpen> {
    return this.http.post<DoorOpen>(
      `${AppConfig.getApiUrl()}/doors/${id}/open`,
      null,
      {
        headers: {'auth-key': AppConfig.authKey},
      });
  }
}
