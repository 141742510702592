import {Injectable} from '@angular/core';
import {Door, DoorListItem, DoorOpen} from './models/door/door';
import {DoorProvider} from '../transport/door.provider';

@Injectable({
  providedIn: 'root',
})
export class DoorService {
  constructor(private doorProvider: DoorProvider) {
  }

  async getDoor(id: string): Promise<Door> {
    return this.doorProvider.getDoor(id).toPromise();
  }

  async getAllDoors(storeHandle: string): Promise<DoorListItem[]> {
    return this.doorProvider.getAllDoors(storeHandle).toPromise();
  }

  async openDoor(id: string): Promise<DoorOpen> {
    return this.doorProvider.openDoor(id).toPromise();
  }
}
